/*==============================
=          GLOBAL  JS          =
==============================*/
$(function() {

	/* Mobile Nav */
	$('.nav-open').click(function(event){
		$('.mobile-nav').addClass('active');
		$('.nav-close').addClass('active');
		$(this).removeClass('active');
	});

	$('.nav-close').click(function(event){
		$('.mobile-nav').removeClass('active');
		$('.nav-open').addClass('active');
		$(this).removeClass('active');
	});

	// Superfish
	$(document).ready(function() {
		$('ul.sf-menu').superfish();
	});
});
